.service { 
    text-align: center;
}

.service-text {
    max-width: 50%;
    margin: 0 auto;
    text-indent: 25px;
}

@media (max-width: 900px){
    .service-text {
        max-width: 95%;
        text-indent: 25px;
    }
}