.contact {
  width: 50%;
  margin: 0 auto;
}

.form-line {
  width: 100%;
  height: 1px;
  stroke: #bababa;
  stroke-width: 2px;
}
  
.form-line .focus,
.form-line .valid,
.form-line .invalid {
  transition: all 0.2s;
  stroke-dasharray: 0, 20;
  stroke-dashoffset: -20;
}
  
.form-line .focus {
  stroke: black;
}

.form-input:focus ~ .form-line .focus,
.form-input:valid ~ .form-line .valid,
.form-input[data-touched]:invalid ~ .form-line .invalid {
  stroke-dasharray: 40, 0;
  stroke-dashoffset: 0;
}