.masonry {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

.masonry_column {
    padding-left: 1%;
    background-clip: padding-box;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-item {
    padding: 2%;
    flex: 1 21%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gallery-item:nth-child(9){
    flex: 0.25 21%;
}

.gallery-item img {
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    transition: transform 2s;
}

.gallery-item img:hover {
    transform: scale(1.05);
    box-shadow: 4px 4px 20px 20px rgba(0,0,0,0.2);
}

.modal-content::-webkit-scrollbar {
    display: none;
}

.modal-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media (max-width: 600px){
    .gallery {
        flex-direction: column;
    }
}