@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Didact+Gothic&display=swap');

$body-background-color: #F5FFFA;

$footer-padding: 2rem 1.5rem 3rem;
$footer-background-color: hsl(0,0%,92%);

$family-primary: 'Didact Gothic', sans-serif;
$family-secondary: 'Arimo', sans-serif;

$navbar-height: 3.5rem;
$navbar-box-shadow-color: 0 6px 0 0;
$navbar-box-shadow-color: black;
$navbar-item-hover-background-color: white;

$primary: #0A2239;
$info: #065A82;
$warning: #132E32;

.footer {
    font-size: 0.8rem;

    .social {
        font-size: 1.5rem;
    }
}

.content {
    font-family: $family-secondary;
}

button.navbar-burger {
    background: inherit;
    border: none;
}

button.navbar-burger:focus {
    outline: 0;
}

@import 'bulma/bulma';